import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _47324bee = () => interopDefault(import('../pages/bloggers/index.vue' /* webpackChunkName: "pages/bloggers/index" */))
const _4fd5edbe = () => interopDefault(import('../pages/kaarsen/index.vue' /* webpackChunkName: "pages/kaarsen/index" */))
const _05c02508 = () => interopDefault(import('../pages/programmas/index.vue' /* webpackChunkName: "pages/programmas/index" */))
const _32676224 = () => interopDefault(import('../pages/zoeken.vue' /* webpackChunkName: "pages/zoeken" */))
const _6814c86a = () => interopDefault(import('../pages/kaarsen/aansteken.vue' /* webpackChunkName: "pages/kaarsen/aansteken" */))
const _079947e8 = () => interopDefault(import('../pages/rubriek/gedichten.vue' /* webpackChunkName: "pages/rubriek/gedichten" */))
const _72388e26 = () => interopDefault(import('../pages/rubriek/link-in-bio.vue' /* webpackChunkName: "pages/rubriek/link-in-bio" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _9fbd5c8c = () => interopDefault(import('../pages/alles-over/_slug/index.vue' /* webpackChunkName: "pages/alles-over/_slug/index" */))
const _3ec78eed = () => interopDefault(import('../pages/artikel/_slug.vue' /* webpackChunkName: "pages/artikel/_slug" */))
const _457baaa6 = () => interopDefault(import('../pages/bloggers/_slug.vue' /* webpackChunkName: "pages/bloggers/_slug" */))
const _040983c0 = () => interopDefault(import('../pages/programmas/_slug.vue' /* webpackChunkName: "pages/programmas/_slug" */))
const _abd78b7a = () => interopDefault(import('../pages/programmas/_slug/index.vue' /* webpackChunkName: "pages/programmas/_slug/index" */))
const _6b0fbb76 = () => interopDefault(import('../pages/programmas/_slug/afleveringen.vue' /* webpackChunkName: "pages/programmas/_slug/afleveringen" */))
const _cbd72170 = () => interopDefault(import('../pages/programmas/_slug/deelnemen.vue' /* webpackChunkName: "pages/programmas/_slug/deelnemen" */))
const _c7acca7c = () => interopDefault(import('../pages/programmas/_slug/extras.vue' /* webpackChunkName: "pages/programmas/_slug/extras" */))
const _371b231a = () => interopDefault(import('../pages/programmas/_slug/over.vue' /* webpackChunkName: "pages/programmas/_slug/over" */))
const _6c38c4dd = () => interopDefault(import('../pages/rubriek/_slug.vue' /* webpackChunkName: "pages/rubriek/_slug" */))
const _7ddd3e06 = () => interopDefault(import('../pages/alles-over/_slug/gedichten.vue' /* webpackChunkName: "pages/alles-over/_slug/gedichten" */))
const _2efaf336 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/bloggers",
    component: _47324bee,
    name: "bloggers"
  }, {
    path: "/kaarsen",
    component: _4fd5edbe,
    name: "kaarsen"
  }, {
    path: "/programmas",
    component: _05c02508,
    name: "programmas"
  }, {
    path: "/zoeken",
    component: _32676224,
    name: "zoeken"
  }, {
    path: "/kaarsen/aansteken",
    component: _6814c86a,
    name: "kaarsen-aansteken"
  }, {
    path: "/rubriek/gedichten",
    component: _079947e8,
    name: "rubriek-gedichten"
  }, {
    path: "/rubriek/link-in-bio",
    component: _72388e26,
    name: "rubriek-link-in-bio"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }, {
    path: "/alles-over/:slug",
    component: _9fbd5c8c,
    name: "alles-over-slug"
  }, {
    path: "/artikel/:slug?",
    component: _3ec78eed,
    name: "artikel-slug"
  }, {
    path: "/bloggers/:slug",
    component: _457baaa6,
    name: "bloggers-slug"
  }, {
    path: "/programmas/:slug",
    component: _040983c0,
    children: [{
      path: "",
      component: _abd78b7a,
      name: "programmas-slug"
    }, {
      path: "afleveringen",
      component: _6b0fbb76,
      name: "programmas-slug-afleveringen"
    }, {
      path: "deelnemen",
      component: _cbd72170,
      name: "programmas-slug-deelnemen"
    }, {
      path: "extras",
      component: _c7acca7c,
      name: "programmas-slug-extras"
    }, {
      path: "over",
      component: _371b231a,
      name: "programmas-slug-over"
    }]
  }, {
    path: "/rubriek/:slug?",
    component: _6c38c4dd,
    name: "rubriek-slug"
  }, {
    path: "/alles-over/:slug?/gedichten",
    component: _7ddd3e06,
    name: "alles-over-slug-gedichten"
  }, {
    path: "/*",
    component: _2efaf336,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
