
export default {
  props: {
    index: {
      type: Number,
      default: 0,
    },
    on: {
      type: Boolean,
      default: true,
    },
    playing: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      randomDuration: 1,
    }
  },
  mounted () {
    this.randomDuration = Math.random() + 0.75
  },
}
