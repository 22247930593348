
import parkSound from '@/assets/sounds/park-with-birds.aac'

export default {
  data () {
    return {
      ambientSound: null,
      ambientSoundFadeIn: null,
      isMounted: false,
      isMenuOpen: false,
      isAmbientVideoPlaying: false,
      isSpotlightOpen: false,
      spotlightCandle: null,
      isSharingPossible: true,
      isCopySuccesfull: false,
      keyword: null,
      menuPrimaryItems: {
        'Steek een kaars aan': '/kaarsen',
        Bloggers: '/bloggers',
        'Programma\'s': {
          expand: true,
          children: {
            'Tv uitzendingen': '/programmas/ik-mis-je',
            'Ik mis je podcast': '/programmas/ik-mis-je-podcast',
          },
        },
        'Ontmoet en deel': '/ontmoet-en-deel',
        'Over ons': '/over-ons',
        Contact: '/contact',
      },
      menuSocialItems: {
        facebook: 'https://www.facebook.com/EOikmisje',
        instagram: 'https://www.instagram.com/eo_ikmisje/',
        spotify: 'https://open.spotify.com/playlist/6rdKMdyprhEMDMzmV8yGtH',
      },
    }
  },
  async fetch () {
    // Get spotlighted candle
    if (this.$route.query.kaars) {
      try {
        const { data: { item = {} } = {} } = await this.$api.candles.getOne(
          this.$route.query.kaars,
          {
            domain: this.$config.preprEnvironmentDomain,
          })
          this.spotlightCandle = item
          this.isSpotlightOpen = true
          this.isCopySuccesfull = false
      } catch (e) {
        this.$log.warn(`Couldn't fetch candle: ${this.$route.query.kaars}.`, e)
        this.spotlightCandle = null
        this.isSpotlightOpen = false
      }
    } else {
      this.spotlightCandle = null
      this.isSpotlightOpen = false
    }
  },
  head: {
    // @todo move this preload to ccm module itself
    link: [
      {
        rel: 'preload',
        as: 'style',
        href: 'https://cookies.eo.nl/sites/EO/eo.nl/npo-lowerbar_v3.1.10.css',
        crossorigin: '',
      },
    ],
  },
  computed: {
    breadcrumbs () {
      return this.$store.getters['breadcrumbs/items'](this.pathWithoutTrailingSlash)
    },
    breadcrumbsWidth () {
      return this.$store.getters['breadcrumbs/width'](this.pathWithoutTrailingSlash)
    },
    isBreadcrumbsFloating () {
      return this.$store.getters['breadcrumbs/floating'](this.pathWithoutTrailingSlash)
    },
    hasBreadcrumbsEoRoot () {
      return this.$store.getters['breadcrumbs/eoRoot'](this.pathWithoutTrailingSlash)
    },
    menuSecondaryItems () {
      return this.subjectList
    },
    pathWithoutTrailingSlash () {
      return this.$route.path.replace(/\/$/, '')
    },
    subjectList () {
      const list = {}
      this.$config.subjects.forEach((s) => {
        list[s.listName] = `/alles-over/${s.slug}`
      })
      return list
    },
  },
  watch: {
    // Another candle is spotlighted
    '$route.query': function () {
      this.isCopySuccesfull = false
      this.$fetch()
    },
  },
  mounted () {
    this.isMounted = true
    this.isSharingPossible = navigator.share || navigator.clipboard
    setTimeout(() => this.startAmbientVideo(), 2000)
  },
  beforeDestroy () {
    if (this.ambientSound) {
      this.ambientSound = null
    }
    if (this.isAmbientVideoPlaying) {
      this.$refs['ambientVideo'].pause()
      this.isAmbientVideoPlaying = false
    }
  },
  methods: {
    startAmbientVideo () {
      if (this.isAmbientVideoPlaying) return
      if (!this.$viewport.smAndUp) return
      if (!this.$refs['ambientVideo']) return
      this.$refs['ambientVideo'].addEventListener('play', () => {
        this.isAmbientVideoPlaying = true
      })
      this.$refs['ambientVideo'].addEventListener('pause', () => {
        this.isAmbientVideoPlaying = false
      })
      this.$refs['ambientVideo'].addEventListener('ended', () => {
        this.isAmbientVideoPlaying = false
      })
      this.$refs['ambientVideo'].play()
    },
    startAmbientSound () {
      if (this.ambientSound) return

      // Create background sounds
      this.ambientSound = new Audio(parkSound)
      this.ambientSound.addEventListener('canplaythrough', () => {
        this.ambientSound.loop = true
        this.ambientSound.autoplay = true
        this.ambientSound.play()
      })
      
      // Fade in background sounds
      const maxIterations = 300
      let iterations = 0
      this.ambientSound.volume = 0
      this.ambientSoundFadeIn = setInterval(() => {
        if (iterations >= maxIterations) {
          clearInterval(this.ambientSoundFadeIn)
        } else {
          iterations++
          this.ambientSound.volume = 0.3 * (iterations / maxIterations)
        }
      }, 10)
    },
    onNavigate () {
      this.isMenuOpen = false
    },
    onSearch (keyword) {
      this.$router.push({
        path: '/zoeken',
        query: {
          invoer: keyword,
        },
      })
      this.isMenuOpen = false
      this.keyword = null
    },
    async shareCandle (id) {
      const text = 'Bekijk dit kaarsje'
      const url = `${this.$config.baseUrl}/kaarsen?kaars=${id}`
      try {
        await navigator.share({ 
          text, 
          url,
        })
      } catch (error) {
        try {
          await navigator.clipboard.writeText(`${text} ${url}`)
          this.isCopySuccesfull = true
        } catch (error) {
          try {
            const el = document.createElement('textarea')
            el.value = `${text} ${url}`
            el.setAttribute('readonly', '')
            el.style.position = 'absolute'
            el.style.left = '-9999px'
            el.style.opacity = '0'
            document.body.appendChild(el)
            el.select()
            document.execCommand('copy')
            document.body.removeChild(el)
            this.isCopySuccesfull = true
          } catch (error) {
            this.isCopySuccesfull = false
            this.isSharingPossible = false
          }
        }
      }
    },
    errorCaptured (error, vm, info) {
      this.$log.error({
        ...error,
        ...info,
      })
    },
  },
}
