const axios = require('axios')
const { constants } = require('../config')

axios.defaults.baseURL = `${constants.EO_API_HOST}/${constants.EO_API_VERSION}`

export default async function ({ redirect, route }) {
  const { fullPath, path, query } = route
  if (path !== '/publication' && path !== '/publication/') {
    return
  }

  const errorPage = `/error${fullPath}`

  const {
    id,
    'model-id': modelId,
    story,
  } = query

  let { role, slug } = query

  const articleRoute = async () => {
    if (!slug) {
      const {
        data: {
          item: {
            slug: pubSlug,
          } = {},
        } = {},
      } = await axios.get(`/articles/${id}`)
      slug = pubSlug
    }
    return redirect(301, `/artikel/${slug}`)
  }

  const personRoute = async () => {
    if (!id && !slug) {
      return redirect(errorPage)
    }

    /**
     * Available routes for different roles
     * order in priority for people with multiple roles
     * key: slug from prepr
     * value: route in webapp
     */
    const availableRoutes = {
      auteur: '/bloggers',
      gastauteur: '/bloggers',
    }

    /**
     * use provided role as priority role if given and available
     */
    let route = role && availableRoutes[role]

    /**
     * Get slug or roles from api when not provided
     */
    if (!route || !slug) {
      const personIdOrQuery = id || `?slug=${slug}`

      const res = await axios.get(`/persons/${personIdOrQuery}`, {
        params: {
          domain: 'generieke-content-eo',
          limit: 1,
        },
      })

      const person = res?.data?.item || res?.data?.items?.[0]
      const roles = person?.items?.role?.items
      if (roles) {
        const firstMatch = Object.entries(availableRoutes).find(([slug]) => {
          return roles.find(item => item.slug === slug)
        })
        if (firstMatch && firstMatch.length === 2) {
          route = firstMatch[1]
        }
      }
      slug = person?.slug
    }

    if (!route) {
      return redirect(errorPage)
    }

    return redirect(301, `${route}/${slug}`)
  }

  const podcastRoute = async () => {
    if (!slug) {
      const {
        data: {
          item: {
            slug: pubSlug,
          } = {},
        } = {},
      } = await axios.get(`/podcasts/${id}`)
      slug = pubSlug
    }
    return redirect(301, `/programmas/${slug}`)
  }

  const programRoute = async () => {
    if (!slug) {
      const {
        data: {
          item: {
            slug:  pubSlug,
          } = {},
        } = {},
      } = await axios.get(`/programs/${id}`, {
        params: {
          aggregate: 0,
        },
      })
      slug = pubSlug
    }
    return redirect(301, `/programmas/${slug}`)
  }

  const pageRoute = async () => {
    if (!slug) {
      const {
        data: {
          item: {
            slug:pubSlug,
          } = {},
        } = {},
      } = await axios.get(`/pages/${id}`)
      slug = pubSlug
    }
    return redirect(301, `/${slug}`)
  }

  if (modelId) {
    switch (modelId) {
      case '9ad0aecf-f7f9-431c-9193-52bc5bc22971': // article
        return await articleRoute()
      case '3e97b652-7f9f-4b47-91ef-5f63b448a983': // person
        return await personRoute()
      case 'a45ca4c5-4010-4608-8174-94354e618901': // podcast
        return await podcastRoute()
      case 'fa9af868-5af3-49db-8b55-ff1155b2a53b': // program
        return await programRoute()
      case 'eceb6d16-a6e8-439c-88d8-286c97a580ff': // page
        return await pageRoute()
      case '07d69113-d504-4c04-9f03-f2338985c96a': // tag
      case 'e207ea24-66a3-469e-b257-91b400bbce50': // (Hoofd)tag
        return redirect(301, `/alles-over/${slug}`)
    }
  }

  if (story) {
    return redirect(301, `/rubriek/${story}`)
  }

  if (slug) {
    return redirect(301, slug)
  }

  redirect(errorPage)
}
