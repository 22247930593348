export default {
  APP_ENV: process.env.APP_ENV || 'production',
  APP_VERSION: process.env.BITBUCKET_TAG || `v0.0.0-rc.${process.env.BITBUCKET_BUILD_NUMBER}`,
  BASE_URL: (process.env.BITBUCKET_PR_ID && `https://pr-${process.env.BITBUCKET_PR_ID}-ik-mis-je.feat.eo.nl`) || process.env.BASE_URL || 'https://ikmisje.eo.nl',
  BASE_URL_CANONICAL: process.env.BASE_URL_CANONICAL || 'https://ikmisje.eo.nl',
  EO_API_HOST: process.env.EO_API_HOST || 'https://prod-api.eo.nl',
  NEW_EO_API_HOST: process.env.NEW_EO_API_HOST || 'https://api.eo.nl',
  EO_API_VERSION: 'v1',
  PREPR_ENVIRONMENT_DOMAIN: process.env.PREPR_ENVIRONMENT_DOMAIN || 'ikmisje-eo',
  PREPR_COMMUNITY_ID: process.env.PREPR_COMMUNITY_ID || '1340eeaa-cb2e-4c7e-9be7-53cd362e3a19',
  PLAYER_KEY: process.env.PLAYER_KEY,
  PLAYER_ANALYTICS_KEY: process.env.PLAYER_ANALYTICS_KEY,
  IMJ_TOPICS: [
    // slug: used for connecting to tag (and model)
    // label: used in breadcrumbs, footer
    // inlineName: used in 'gedichten' pages
    // listName: used in main-menu
    {
      slug: 'ik-mis-mijn-kind',
      label: 'Ik mis mijn kind',
      inlineName: 'verlies van een kind',
      listName: 'Kind',
    },
    {
      slug: 'ik-mis-mijn-kleinkind',
      label: 'Ik mis mijn kleinkind',
      inlineName: 'verlies van een kleinkind',
      listName: 'Kleinkind',
    },
    {
      slug: 'ik-mis-mijn-partner',
      label: 'Ik mis mijn partner',
      inlineName: 'verlies van een partner',
      listName: 'Partner',
    },
    {
      slug: 'ik-mis-mijn-ouder',
      label: 'Ik mis mijn ouder',
      inlineName: 'verlies van een ouder',
      listName: 'Ouder',
    },
    {
      slug: 'ik-mis-mijn-oma-opa',
      label: 'Ik mis mijn oma/opa',
      inlineName: 'verlies van een oma/opa',
      listName: 'Oma/opa',
    },
    {
      slug: 'ik-mis-mijn-vriend-vriendin',
      label: 'Ik mis mijn vriend(in)',
      inlineName: 'verlies van een vriend(in)',
      listName: 'Vriend(in)',
    },
    {
      slug: 'ik-mis-mijn-broer-zus',
      label: 'Ik mis mijn broer/zus',
      inlineName: 'verlies van een broer/zus',
      listName: 'Broer/zus',
    },
    {
      slug: 'verlies-door-zelfdoding',
      label: 'Verlies door zelfdoding',
      inlineName: 'verlies door zelfdoding',
      listName: 'Verlies door zelfdoding',
    },
    {
      slug: 'rouw-en-kinderen',
      label: 'Rouw en kinderen',
      inlineName: 'Rouw en kinderen',
      listName: 'Rouw en kinderen',
    },
  ],
}