
export default {
  props: {
    uid: {
      type: [String, Number],
      default: null,
    },
    receiver: {
      type: String,
      default: null,
    },
    drawing: {
      type: String,
      default: null,
    },
    message: {
      type: String,
      default: null,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    paused: {
      type: Boolean,
      default: false,
    },
    appear: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data () {
    return {
      amountVisible: 0, // value from 0 to 1
      randomDuration: 1,
    }
  },
  computed: {
    isPlaying () {
      return !this.paused && this.amountVisible <= 1 && this.amountVisible > 0
    },
  },
  created () {
    if (!this.appear) {
      this.amountVisible = 1
    }
  },
  mounted () {
    this.randomDuration = Math.random() + 0.75
  },
  methods: {
    async onViewportAppear (ratio) {
      if (this.appear) {
        requestAnimationFrame(() => {
          this.amountVisible = ratio
        })
      }
    },
    scrollToTop () {
      window.scrollTo({top: 0, behavior: 'smooth' })
    },
  },
}
