export const EoLikeButton = () => import('../../node_modules/@eo/nuxt-like/lib/components/EoLikeButton.vue' /* webpackChunkName: "components/eo-like-button" */).then(c => wrapFunctional(c.default || c))
export const CandleAnimation = () => import('../../components/CandleAnimation.vue' /* webpackChunkName: "components/candle-animation" */).then(c => wrapFunctional(c.default || c))
export const CandleItem = () => import('../../components/CandleItem.vue' /* webpackChunkName: "components/candle-item" */).then(c => wrapFunctional(c.default || c))
export const PreprPage = () => import('../../components/PreprPage.vue' /* webpackChunkName: "components/prepr-page" */).then(c => wrapFunctional(c.default || c))
export const CandleWizardBreathe = () => import('../../components/CandleWizard/CandleWizardBreathe.vue' /* webpackChunkName: "components/candle-wizard-breathe" */).then(c => wrapFunctional(c.default || c))
export const CandleWizardBreatheIntro = () => import('../../components/CandleWizard/CandleWizardBreatheIntro.vue' /* webpackChunkName: "components/candle-wizard-breathe-intro" */).then(c => wrapFunctional(c.default || c))
export const CandleWizardEmotion = () => import('../../components/CandleWizard/CandleWizardEmotion.vue' /* webpackChunkName: "components/candle-wizard-emotion" */).then(c => wrapFunctional(c.default || c))
export const CandleWizardEnd = () => import('../../components/CandleWizard/CandleWizardEnd.vue' /* webpackChunkName: "components/candle-wizard-end" */).then(c => wrapFunctional(c.default || c))
export const CandleWizardEnvironment = () => import('../../components/CandleWizard/CandleWizardEnvironment.vue' /* webpackChunkName: "components/candle-wizard-environment" */).then(c => wrapFunctional(c.default || c))
export const CandleWizardLightUp = () => import('../../components/CandleWizard/CandleWizardLightUp.vue' /* webpackChunkName: "components/candle-wizard-light-up" */).then(c => wrapFunctional(c.default || c))
export const CandleWizardMessage = () => import('../../components/CandleWizard/CandleWizardMessage.vue' /* webpackChunkName: "components/candle-wizard-message" */).then(c => wrapFunctional(c.default || c))
export const CandleWizardName = () => import('../../components/CandleWizard/CandleWizardName.vue' /* webpackChunkName: "components/candle-wizard-name" */).then(c => wrapFunctional(c.default || c))
export const CandleWizardOneWord = () => import('../../components/CandleWizard/CandleWizardOneWord.vue' /* webpackChunkName: "components/candle-wizard-one-word" */).then(c => wrapFunctional(c.default || c))
export const CandleWizardPoem = () => import('../../components/CandleWizard/CandleWizardPoem.vue' /* webpackChunkName: "components/candle-wizard-poem" */).then(c => wrapFunctional(c.default || c))
export const CandleWizardPoemIntro = () => import('../../components/CandleWizard/CandleWizardPoemIntro.vue' /* webpackChunkName: "components/candle-wizard-poem-intro" */).then(c => wrapFunctional(c.default || c))
export const CandleWizardRelation = () => import('../../components/CandleWizard/CandleWizardRelation.vue' /* webpackChunkName: "components/candle-wizard-relation" */).then(c => wrapFunctional(c.default || c))
export const CandleWizardStart = () => import('../../components/CandleWizard/CandleWizardStart.vue' /* webpackChunkName: "components/candle-wizard-start" */).then(c => wrapFunctional(c.default || c))
export const IkMisJeCardArticle = () => import('../../components/IkMisJeCard/Article.vue' /* webpackChunkName: "components/ik-mis-je-card-article" */).then(c => wrapFunctional(c.default || c))
export const IkMisJeCardDefault = () => import('../../components/IkMisJeCard/Default.vue' /* webpackChunkName: "components/ik-mis-je-card-default" */).then(c => wrapFunctional(c.default || c))
export const IkMisJeCardEpisode = () => import('../../components/IkMisJeCard/Episode.vue' /* webpackChunkName: "components/ik-mis-je-card-episode" */).then(c => wrapFunctional(c.default || c))
export const IkMisJeCardProgram = () => import('../../components/IkMisJeCard/Program.vue' /* webpackChunkName: "components/ik-mis-je-card-program" */).then(c => wrapFunctional(c.default || c))
export const IkMisJeCard = () => import('../../components/IkMisJeCard/index.vue' /* webpackChunkName: "components/ik-mis-je-card" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
