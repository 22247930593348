
export default {
  data () {
    return {
      isMounted: false,
      isMenuOpen: false,
      headerActiveTab: null,
      keyword: null,
      menuPrimaryItems: {
        'Steek een kaars aan': '/kaarsen',
        Bloggers: '/bloggers',
        'Programma\'s': {
          expand: true,
          children: {
            'Tv uitzendingen': '/programmas/ik-mis-je',
            'Ik mis je podcast': '/programmas/ik-mis-je-podcast',
          },
        },
        'Ontmoet en deel': '/ontmoet-en-deel',
        'Over ons': '/over-ons',
        Contact: '/contact',
      },
      menuSocialItems: {
        facebook: 'https://www.facebook.com/EOikmisje',
        instagram: 'https://www.instagram.com/eo_ikmisje/',
        spotify: 'https://open.spotify.com/playlist/6rdKMdyprhEMDMzmV8yGtH',
      },
      footerSocials: {
        'Volg ons op ...': {
          facebook: {
            name: 'Facebook',
            path: 'https://www.facebook.com/EOikmisje',
          },
          instagram: {
            name: 'Instagram',
            path: 'https://www.instagram.com/eo_ikmisje/',
          },
          spotify: {
            name: 'Spotify',
            path: 'https://open.spotify.com/playlist/6rdKMdyprhEMDMzmV8yGtH',
          },
        },
      },
    }
  },
  head: {
    // @todo move this preload to ccm module itself
    link: [
      {
        rel: 'preload',
        as: 'style',
        href: 'https://cookies.eo.nl/sites/EO/eo.nl/npo-lowerbar_v3.1.10.css',
        crossorigin: '',
      },
    ],
  },
  computed: {
    breadcrumbs () {
      return this.$store.getters['breadcrumbs/items'](this.pathWithoutTrailingSlash)
    },
    breadcrumbsWidth () {
      return this.$store.getters['breadcrumbs/width'](this.pathWithoutTrailingSlash)
    },
    footerShortcuts () {
      return {
        'Ik mis mijn ...': this.subjectList,
      }
    },
    isBreadcrumbsFloating () {
      return this.$store.getters['breadcrumbs/floating'](this.pathWithoutTrailingSlash)
    },
    hasBreadcrumbsEoRoot () {
      return this.$store.getters['breadcrumbs/eoRoot'](this.pathWithoutTrailingSlash)
    },
    menuSecondaryItems () {
      return this.subjectList
    },
    pathWithoutTrailingSlash () {
      return this.$route.path.replace(/\/$/, '')
    },
    subjectList () {
      const list = {}
      this.$config.subjects.forEach((s) => {
        list[s.listName] = `/alles-over/${s.slug}`
      })
      return list
    },
  },
  mounted () {
    this.isMounted = true
  },
  methods: {
    onNavigate () {
      this.isMenuOpen = false
    },
    onSearch (keyword) {
      this.$router.push({
        path: '/zoeken',
        query: {
          invoer: keyword,
        },
      })
      this.isMenuOpen = false
      this.keyword = null
    },
    errorCaptured (error, vm, info) {
      this.$log.error({
        ...error,
        ...info,
      })
    },
  },
}
