// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/candles-background.webp");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body,html{background-color:#6f812d}.eo-application.candles-application{min-height:100svh}.candles-main{overflow:hidden}.candles-main.eo-main{position:relative}.candles-main__bg{background:fixed 50%/cover no-repeat #6f812d url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");bottom:0;height:100dvh;left:0;position:fixed;right:0;top:0;width:100vw;z-index:-1}.candles-main__bg-video{bottom:0;height:100%;left:0;-o-object-fit:cover;object-fit:cover;position:absolute;right:0;top:0;transition:opacity 8s ease;width:100%}.spotlight-candle.candle-item{--size:1.15;pointer-events:none}@media(min-width:600px){.spotlight-candle.candle-item{--size:1.5}}.candles-fade-enter-active,.candles-fade-leave-active{transition:opacity .75s cubic-bezier(.25,.8,.25,1)}.candles-fade-enter,.candles-fade-leave-to{opacity:0}.brand-ik-mis-je{margin:-10px 0!important}@media(min-width:360px){.brand-ik-mis-je{margin-bottom:-5px!important;margin-left:-10px!important}}@media(min-width:900px){.brand-ik-mis-je{margin-left:10px!important}}.brand-eo{margin:-10px -100% -10px auto!important}@media(min-width:360px){.brand-eo{margin-left:20px!important;margin-right:-30px!important}}.brand-npo.eo-broadcasters{display:none}@media(min-width:360px){.brand-npo.eo-broadcasters{display:inline-flex}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
